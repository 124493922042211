import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { RequestUrlComponents, useDrupalJsonApi } from '~/composables/api/drupal-json-api';
import { objectHasKey } from '~/common/object';
import { AuthorizationHeader, useAuthStore } from '~/store/auth';
import { notEmpty } from '~/common/empty';
import { useApiBaseUrlStore } from '~/store/api-base-url';
import { AddToCartItemMetadata } from '~/composables/api/commerce/cart';
import { TaxonomyTermDataIncluded } from '~/types/json-api/taxonomy';
import { MediaRemoteVideo } from '~/types/json-api/media';
import { FileDataInclude } from '~/types/json-api/file';
import { ProductData, ProductVariationDataIncluded, StoreData } from '~/types/json-api/commerce';
import { JsonResponseIncluded } from '~/types/json-api/json-api';
import { DrupalCommercePrice } from '~/types/drupal-common';
import { ImageStyle, ShippingPlugin } from '~/types/enum/enum';
import { CalculatedSpec, CharacteristicSet } from '~/types/json-api/characteristic';
import { DateTimeString } from '~/types/types';
import { UserDataIncluded } from '~/types/json-api/user';

export const ProductType = {
  INSTRUMENT: 'instrument',
  BOW: 'bow',
  NON_INSTRUMENT: 'non_instrument',
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];

export interface BundlesParams {
  bundles: ProductType[],
  params?: DrupalJsonApiParams
}

/**
 * The options to pass into the json query.
 */
export interface ProductQueryOptions {
  bundles?: ProductType[],
  excludeBundles?: boolean,
  uuid?: string,
  storeId?: string,
  limit?: number
}

export interface ProductCardData {
  created: DateTimeString,
  title: string,
  category: string,
  href: string,
  id: string,
  price: string,
  listPrice: string | null | undefined,
  img: string,
  store?: {
    name: string,
    id: string,
  },
  tags: {
    isMaker: boolean,
    isRestorer: boolean,
    hasVideo: boolean,
  }
  type: ProductType,
  published: boolean
}

export interface ProductMetadata {
  addToCartMetadata: AddToCartItemMetadata,
  calculatedSpecs: CalculatedSpec[],
  category: string,
  characteristics: CharacteristicSet[],
  created: DateTimeString,
  description: string,
  freeShipping: boolean,
  hasVideo: boolean,
  href: string,
  imgUrls: string[],
  isMaker: boolean,
  isNegotiable: boolean,
  isRestorer: boolean,
  listPrice: DrupalCommercePrice | null,
  price: DrupalCommercePrice,
  published: boolean,
  requirePurchaseRequest: boolean,
  specs: string[],
  store: StoreData | null,
  storeMeta: {
    name: string,
    id: string,
    href: string,
  },
  title: string,
  type: ProductType,
  uid: string,
  variation: ProductVariationDataIncluded | null,
  videoUrl: string | null,
}

export const mapToProductType = (input: string): ProductType | null => {
  const mapping: { [key: string]: ProductType } = {
    'product--instrument': ProductType.INSTRUMENT,
    'product--bow': ProductType.BOW,
    'product--non-instrument': ProductType.NON_INSTRUMENT,
  };

  return mapping[input] || null; // or throw an error if you prefer
};

export const productTypeToReadableString = (productType: ProductType): string => {
  const mapping: { [key in ProductType]: string } = {
    [ProductType.INSTRUMENT]: 'Instrument',
    [ProductType.BOW]: 'Bow',
    [ProductType.NON_INSTRUMENT]: 'Tool or Supply',
  };

  return mapping[productType] || '';
};

export const productTypeToReadableStringMultiple = (productType: ProductType): string => {
  const mapping: { [key in ProductType]: string } = {
    [ProductType.INSTRUMENT]: 'Instruments',
    [ProductType.BOW]: 'Bows',
    [ProductType.NON_INSTRUMENT]: 'Tools & Supplies',
  };

  return mapping[productType] || '';
};

/**
 * Provides methods to interact with products via api.
 */
export const useProductApi = () => {
  const drupalJsonApi = useDrupalJsonApi();
  const apiBaseUrlStore = useApiBaseUrlStore();
  const authStore = useAuthStore();

  const buildFetchProductsRequestComponents = (queryOptions: ProductQueryOptions, apiParams: DrupalJsonApiParams = new DrupalJsonApiParams()): RequestUrlComponents => {
    apiParams.addInclude([
      'default_variation',
      'default_variation.uid',
      'stores',
      'field_product_images',
    ])
      .addSort('created', 'DESC');
    if (queryOptions.limit) {
      apiParams.addPageLimit(queryOptions.limit);
    }

    if (queryOptions.hasOwnProperty('storeId') && queryOptions.storeId) {
      apiParams.addFilter('stores.id', queryOptions.storeId, 'IN');
    }

    if (queryOptions.bundles) {
      apiParams.addFilter('product_type.meta.drupal_internal__target_id', queryOptions.bundles, queryOptions.excludeBundles
        ? 'NOT IN'
        : 'IN');
    }

    if (queryOptions.hasOwnProperty('uuid') && queryOptions.uuid) {
      apiParams.addFilter('id', queryOptions.uuid);
    }

    if (!apiParams.getQueryObject()
      .filter
      .hasOwnProperty('status')) {
      apiParams.addFilter('status', '1');
    }

    // console.log({ apiParams });

    return {
      entityType: 'product',
      isCommerce: true,
      params: apiParams,
    };
  };

  const parseProductMetadata = (product: ProductData, response: JsonResponseIncluded<ProductData | ProductData[]>, imageStyle?: ImageStyle): ProductMetadata | null => {
    const store: StoreData | null = pipe(
      drupalJsonApi.parseIncluded<StoreData>(response, product.relationships.stores.data[0].id),
      E.match(() => null, (x) => x),
    );

    const variationData = product.relationships.default_variation.data;
    const variation = variationData
      ? pipe(
        drupalJsonApi.parseIncluded<ProductVariationDataIncluded>(response, product.relationships.default_variation.data.id),
        E.match(() => null, (x) => x),
      )
      : null;
    if (!variation) {
      return null;
    }

    // not sure why, but some products don't have a uid relationship?
    const ownerId = variation.relationships.uid
      ? pipe(
        drupalJsonApi.parseIncluded<UserDataIncluded>(response, variation.relationships.uid.data.id),
        E.match(() => null, (x) => x.id),
      )
      : null;

    const videoMedia = !product.relationships.field_video || !product.relationships.field_video.data
      ? null
      : pipe(
        drupalJsonApi.parseIncluded<MediaRemoteVideo>(response, product.relationships.field_video.data.id),
        E.match(() => null, (x) => x),
      );

    // Initially, product pages and product cards leveraged two
    // different ways of determining product image URLs. At time
    // of writing, didn't want to figure out how exactly the
    // implementations differed, so just use the card version if
    // an imageStyle is provided and the bulkier product page
    // version if not.
    const imagesData = product.relationships.field_product_images.data;
    const imgUrls = imageStyle
      ? drupalJsonApi.parseProductImageUrls(response, product, imageStyle)
      : imagesData
        ? (() => {
          const productImageUuids = imagesData.map((x) => x.id);
          const productImagesData: FileDataInclude[] = productImageUuids.map((id) => pipe(
            drupalJsonApi.parseIncluded<FileDataInclude>(response, id),
            E.match(() => null, (x) => x),
          ))
            .filter(notEmpty);
          return productImagesData.map((x) => apiBaseUrlStore.apiBaseUrl + x.attributes.uri.url);
        })()
        : [];

    const getIncludedTermName = (field: string) => {
      if (!objectHasKey(product.relationships, field)
        || !product.relationships[field]) {
        return null;
      }

      const term: TaxonomyTermDataIncluded | null = pipe(
        drupalJsonApi.parseIncluded<TaxonomyTermDataIncluded>(response, product.relationships[field].data.id),
        E.match(() => null, (x) => x),
      );

      return term
        ? term.attributes.name
        : null;
    };

    return {
      // At time of writing, this is included specifically for the
      // product page, and doesn't accommodate changing quantity.
      // When this behavior changes, this may need to be updated
      // and/or decoupled. May be enough to just set this to a ref
      // in the context it's being used and then set its quantity.
      addToCartMetadata: {
        type: product.relationships.default_variation.data.type,
        id: product.relationships.default_variation.data.id,
        meta: {
          quantity: 1,
        },
      },
      created: product.attributes.created,
      characteristics: product.attributes.enabled_characteristics || [],
      description: product.attributes.body?.processed,
      freeShipping: store
        ? store.attributes.field_shipping_method.target_plugin_id === ShippingPlugin.SHIPPO_FREE
        : false,
      hasVideo: product.attributes.has_video,
      href: product.attributes.path.alias,
      imgUrls,
      isMaker: product.attributes.field_is_maker || false,
      isRestorer: product.attributes.field_is_restorer || false,
      isNegotiable: variation.attributes.field_wholesale_negotiable || false,
      requirePurchaseRequest: variation.attributes.field_require_purchase_request || false,
      listPrice: variation.attributes.list_price,
      price: variation.attributes.price,
      published: product.attributes.status,
      category: product.attributes.category,
      specs: product.attributes.field_specifications || [],
      calculatedSpecs: product.attributes.calculated_specs || [],
      store,
      storeMeta: {
        name: store.attributes.name,
        href: store.attributes.path.alias,
        id: store.id,
      },
      title: product.attributes.title,
      type: mapToProductType(product.type),
      uid: ownerId,
      variation,
      videoUrl: videoMedia
        ? videoMedia.attributes.field_media_oembed_video
        : null,
    };
  };

  return {
    /**
     * Fetches products via api.
     */
    fetchProducts: (
      queryOptions: ProductQueryOptions,
      apiParams: DrupalJsonApiParams = new DrupalJsonApiParams(),
      authHeader: AuthorizationHeader | null = null,
    ) => (authHeader
      ? drupalJsonApi.get<ProductData[]>(
        buildFetchProductsRequestComponents(queryOptions, apiParams),
        authHeader,
      )
      : drupalJsonApi.get<ProductData[]>(
        buildFetchProductsRequestComponents(queryOptions, apiParams),
      )
    ),

    /**
     * Parses data for the given product from the response for use in cards.
     *
     * @param product - The product from the response.
     * @param response - The response that includes the product.
     * @param includeStore - Whether to include the store metadata.
     * @param imageStyle
     */
    parseCardData: (product: ProductData, response: JsonResponseIncluded<ProductData | ProductData[]>, includeStore: boolean = true, imageStyle: ImageStyle = ImageStyle.PORTRAIT_600x800): ProductCardData | null => {
      const meta = parseProductMetadata(product, response, imageStyle);
      if (!meta) {
        // eslint-disable-next-line no-console
        console.warn('Hiding product due to missing variation or metadata:', product.attributes.title);

        return null;
      }

      return {
        created: meta.created,
        href: meta.href,
        id: product.id,
        img: meta.imgUrls[0],
        tags: {
          hasVideo: meta.hasVideo,
          isMaker: meta.isMaker,
          isRestorer: meta.isRestorer,
        },
        type: mapToProductType(product.type),
        listPrice: meta.listPrice?.formatted.replace('.00', ''),
        price: meta.price?.formatted.replace('.00', ''),
        category: meta.category,
        published: product.attributes.status,
        store: meta.storeMeta,
        title: meta.title,
      };
    },

    parseProductMetadata,
  };
};

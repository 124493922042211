<!--
  In theory, it might make more sense for this logic to exist
  as a composable instead of a renderless component. However,
  implementing it as a component gives us the ability to
  universally populate the header information in onServerPrefetch(),
  ensuring that it will be available in SSR no matter the context.
  This also helps to indicate that implementing components may need
  to implement their on onServerPrefetch().
-->
<template />

<script lang="ts" setup>
import { useHead } from '@vueuse/head';
import type { MetaSimple } from '~/types/types';
import { ResponseDataCommon } from '~/types/json-api/json-api';

export interface MetaHead {
  title: string,
  meta: {
    name: string,
    content: string
  }[]
}

const props = defineProps({
  // If the head should be provided statically.
  metaSimple: {
    type: Object as () => MetaSimple,
    required: false,
    default: {
      title: 'Luthiery Supplies, Instruments, and Bows | Handcrafted',
      description: 'Handcrafted is an artisan marketplace for luthiery and instrument making supplies, as well as handmade instruments and bows.',
    },
  },
  // metaSimple overrides
  title: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  // If the head needs to be derived from a response's metatag prop.
  responseData: {
    type: Object as () => ResponseDataCommon,
    required: false,
  },
});

const metaHead: MetaHead = {
  title: props.title || props.metaSimple.title,
  meta: [
    {
      name: 'description',
      content: props.description || props.metaSimple.description,
    },
  ],
};

const popMeta = () => {
  if (!props.responseData || !props.responseData.attributes.metatag) {
    useHead(metaHead);
    // console.log(metaHead)
    return;
  }

  const responseMeta = props.responseData.attributes.metatag;
  // console.log(responseMeta);
  const title = responseMeta.filter((x) => x.tag === 'meta' && x.attributes.name && x.attributes.name === 'title')[0].attributes.content || null;
  if (title) {
    metaHead.title = title;
  }

  const additionalTags = responseMeta
    .filter((x) => x.tag === 'meta'
      && (x.attributes.name || x.attributes.property))
    .map((x) => x.attributes);
  if (additionalTags.length > 0) {
    // @ts-ignore
    metaHead.meta = additionalTags;
  }

  // console.log(metaHead);
  useHead(metaHead);
};

onServerPrefetch(popMeta);
onBeforeMount(popMeta);
</script>

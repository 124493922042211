import { createConstEnum } from '~/common/enum';

/**
 * A utility type that extracts the value type of a const enum-like object.
 * The type parameter `T` represents a const enum-like object with readonly values.
 *
 * @typeparam T - A const enum-like object with readonly values. This will
 * default to a simple Readonly<Record<keyof T, infer V>> if not provided.
 *
 * @returns The value type of the const enum-like object.
 *
 * @example
 * const MyEnumObject = createConstEnum({
 *   A: 'a',
 *   B: 'b',
 *   C: 'c',
 * });
 *
 * type MyEnumType = EnumType<typeof MyEnumObject>;
 * // MyEnumType type is 'a' | 'b' | 'c'
 */
export type EnumType<T> = T extends Readonly<Record<keyof T, infer V>> ? V : never;

export const ShippingPlugin = createConstEnum({
  SHIPPO: 'shippo',
  SHIPPO_FREE: 'shippo_free',
});
export type ShippingPlugin = EnumType<typeof ShippingPlugin>;

export const ImageStyle = createConstEnum({
  WIDTH_600: 'width_600',
  PORTRAIT_600x800: '3_4_sc_600x800',
  LANDSCAPE_800x600: '4_3_sc_800x600',
  ARTICLE_800: '4_1_5_sc_800x300',
  ARTICLE_1600: '4_1_5_sc_1600x600',
  LARGE: 'large',
  PRODUCT_PRIMARY: 'product_primary',
});

export type ImageStyle = EnumType<typeof ImageStyle>;

export const MetatagTag = createConstEnum({
  LINK: 'link',
  META: 'meta',
});
export type MetatagTag = EnumType<typeof MetatagTag>;

export const NonInstrumentProductTypeTerm = createConstEnum({
  ACCESSORIES: '0e9c59a9-7259-40cf-a7ea-066411d39d13',
});

export type NonInstrumentProductTypeTerm = EnumType<typeof NonInstrumentProductTypeTerm>;

export const CookieName = createConstEnum({
  UPDATE_ROLES: 'directive__roles_updated',
});

export type CookieName = EnumType<typeof CookieName>;

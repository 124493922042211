<template>
  <div
    :data-uuid="uuid"
    class="blade"
    :class="[`blade--${theme}`,`blade--${k}`, {'blade--reverse': reverse, 'blade--noImg': !imgSrc}]"
  >
    <div class="blade__col">
      <h1
        class="blade__h1"
        :class="[`blade__h1--${theme}`, `blade__h1--${k}`, {'blade__h1--red': !!subheading}]"
      >
        {{ heading }}
      </h1>
      <h2 v-if="subheading">
        {{ subheading }}
      </h2>
      <div v-html="bodyNoNbsp" />
      <slot name="cta" />
    </div>
    <div class="blade__col">
      <template v-if="imgSrc">
        <RoundedImg :src="imgSrc" />
      </template>
      <template v-else>
        <div class="line" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { v4 as generateUuid } from 'uuid';

const uuid = generateUuid();
const componentSelector = `[data-uuid="${uuid}"]`;

const props = defineProps({
  heading: {
    required: true,
    type: String,
  },
  subheading: {
    required: false,
    type: String,
  },
  body: {
    required: true,
    type: String,
  },
  imgSrc: {
    required: false,
    type: String,
  },
  reverse: {
    required: false,
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    required: true,
    default: 'light',
  },
  k: {
    type: Number,
    required: true,
  },
});

// This is very important for our AC form embeds.
// CKeditor inserts an &nbsp; into empty elements,
// and the AC script embed requires the use of a
// totally empty element to function correctly.
// If we don't strip this out, there are problems.
const bodyNoNbsp = props.body.replace('&nbsp;', '');

onMounted(() => {
  watchModifyFormStyles();
  addAcFormClasses();
  reInsertScripts();
});

/**
 * Reinserts all script tags on the page.
 *
 * Vue's v-html leverages innerHTML to insert markup. However,
 * because of how JS works, scripts appended after the initial
 * DOM load will not execute. In order for inline scripts from
 * Drupal WYSIWYG fields to function (such as for ActiveCampaign
 * list sign up forms) we need to replace them with clones.
 */
const reInsertScripts = () => {
  // https://stackoverflow.com/a/20584396/1471267
  function nodeScriptReplace(node) {
    if (nodeIsFreshScript(node) === true) {
      node.parentNode.replaceChild(nodeScriptClone(node), node);
    } else {
      let i = -1; const
        children = node.childNodes;
      while (++i < children.length) {
        nodeScriptReplace(children[i]);
      }
    }

    return node;
  }

  function nodeScriptClone(node) {
    const script = document.createElement('script');
    script.text = node.innerHTML;
    script.setAttribute('data-reinserted', 'true');

    let i = -1; const attrs = node.attributes; let
      attr;
    while (++i < attrs.length) {
      script.setAttribute((attr = attrs[i]).name, attr.value);
    }
    return script;
  }

  function nodeIsFreshScript(node) {
    return node.tagName === 'SCRIPT' && node.getAttribute('data-reinserted') !== 'true';
  }

  nodeScriptReplace(document.querySelector(componentSelector));
};

/**
 * Add the ac form class to the form container
 */
const addAcFormClasses = () => {
  const script = document.querySelector(`${componentSelector} script[src*="activehosted.com"]`);
  if (!script) {
    return;
  }

  const formContainer = script.previousElementSibling;
  if (!formContainer) {
    return;
  }

  formContainer.classList.add('ac-form-container');
};

/**
 * Modifies the styles on the AC form elements.
 */
const watchModifyFormStyles = () => {
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      mutation.addedNodes.forEach((newNode) => {
        if (newNode.querySelector) {
          // Remove the inserted AC styles so that the form
          // gets our standard styling.
          if (newNode.tagName === 'STYLE') {
            newNode.remove();
            return;
          }

          const submit = newNode.querySelector('._submit');
          if (!submit) {
            return;
          }

          submit.classList.add('btn', 'md', 'warm');
        }
      });
    });
  });

  observer.observe(document.querySelector(componentSelector), { subtree: true, childList: true });
};

</script>

<style scoped lang="scss">
.blade {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  row-gap: var(--space-lg);

  @media (min-width: 1030px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 0 var(--space-xl);
    padding: var(--space-xl) 0;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &--noImg {
    overflow: hidden;
  }

  &--dark {
    background: var(--color--bg-dark);
    color: white;

    :deep(a:not(.btn)) {
      color: var(--color--link--on-dark);
    }

    &:not([class*=--0]) h1 {
      color: var(--color--on-dark--red);
    }
  }

  &--0 {
    padding: var(--space-std) 0 var(--space-xl);
  }

  &:not([class*=--0]) {
    padding: var(--space-lg) 0;
  }

  &__h1 {
    &:not([class*=--0]) {
      font-size: var(--text-heading--h2);
    }

    &--red {
      font-family: var(--font-family--body);
      font-size: var(--text-md) !important;
      font-weight: 600;
      color: var(--color--on-light--red);
      margin-bottom: var(--space-xxxs);

      &[class*=--dark] {
        color: var(--color--on-dark--red);
      }
    }
  }

  &__col {
    width: 100%;
    max-width: var(--width--blade-max--1-col);
    //width: calc(50% - (var(--gap) / 2));
  }
}

.line {
  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: '';
    height: 2px;
    width: 100%;
    background-color: var(--color--brand-primary);
  }
}

// Because they are inside of v-html
:deep(p) {
  &:first-child {
    margin-top: var(--space-sm);
  }

  &:last-child {
    margin-bottom: var(--space-std);
  }
}

:deep(img) {
  border: solid 1px var(--color--border)
}
</style>

<script setup lang="ts">
// Some boilerplate example for setting locales.
//
import { useI18n } from 'vue-i18n';
import BaseBranding from '~/components/logos/BaseBranding.vue';
import UlUnlisted from '~/components/utility/UlUnlisted.vue';

const { t } = useI18n();
// const { t, availableLocales, locale } = useI18n()
//
// const toggleLocales = () => {
//   // change to some real logic
//   const locales = availableLocales
//   locale.value = locales[(locales.indexOf(locale.value) + 1) % locales.length]
// }
</script>

<template>
  <footer>
    <NewsletterSignupUpdates />

    <div class="footer-container">
      <BaseBranding />
      <nav>
        <UlUnlisted>
          <li>
            <router-link to="/faq">
              Frequently Asked Questions
            </router-link>
          </li>
          <li>
            <router-link to="/contact">
              Contact Us
            </router-link>
          </li>
          <li>
            <router-link to="/lp/become-a-seller">
              Become a Seller
            </router-link>
          </li>
          <li>
            <router-link to="/legal/terms-use">
              Terms of Use
            </router-link>
          </li>
          <li>
            <router-link to="/legal/privacy-policy">
              Privacy Policy
            </router-link>
          </li>
        </UlUnlisted>
      </nav>
      <div class="copyright">
        ©{{ new Date().getFullYear() }} Handcrafted Market LLC
      </div>
    </div>
  </footer>
</template>

<style scoped>
* {
  color: white;
}

footer {
  margin-top: auto;
}

.footer-container {
  background: var(--color--bg-dark);
  padding: var(--space-xl) var(--space-std);
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-std);
  border-top: solid 2px var(--color--on-dark--purple);
}

.copyright {
  font-size: var(--text-xs);
}

</style>

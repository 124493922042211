<template>
  <TheHubspot />
  <router-view />
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/store/auth';
import { platformShBackendPrefix, useApiBaseUrlStore } from '~/store/api-base-url';
import { useStreamChatStore } from '~/store/stream-chat';
import { useUserApi } from '~/composables/api/non-commerce/user';
import { CookieAction, deleteCookie, watchCookie } from '~/common/cookie';
import { CookieName } from '~/types/enum/enum';

console.warn('CORS allows wildcard connecting domains. This probably should not be allowed on production.');

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
useHead({
  title: 'Handcrafted',
  meta: [
    {
      name: 'description',
      content: 'An instrument marketplace.',
    },
  ],
  link: [
    {
      rel: 'stylesheet',
      href: 'https://use.typekit.net/alf7zqz.css',
      defer: true,
    },
  ],
});
const urlStore = useApiBaseUrlStore();
const authStore = useAuthStore();
const router = useRouter();
const chatStore = useStreamChatStore();
const userApi = useUserApi();

const enableHuspot = ref(false);

const verifyAuth = (async () => {
  // If this pattern needs to be used around the app then we
  // should add an action to execute the logic. For now it's
  // just in here for the sake of simplicity.
  if (authStore && authStore.isAuthenticated) {
    if (!authStore.tokenIsExpired) {
      authStore.startExpireTimer(authStore.tokenExpiration);
    } else {
      const successfullyRefreshed = await authStore.refreshAccessToken();
      if (!successfullyRefreshed) {
        authStore.autoLogout();
      } else {
        authStore.startExpireTimer(authStore.tokenExpiration);
      }
    }
  }
})();

// const didAutoLogout = computed(() => (authStore
//   ? authStore.didAutoLogout
//   : null));
// watch(didAutoLogout, (newVal, oldVal) => {
//   if (newVal && newVal !== oldVal) {
//     router.replace('/logged-out');
//   }
// });

/**
 * Redirects launch landing pages to the dynamic route versions.
 *
 * Attempted to drop this logic into router.beforeEach() in main.ts,
 * but it doesn't quite work. See the note in there.
 *
 * Note that these redirects are also defined in our server index.js.
 * Additional redirects also need to go in there.
 */
const redirectOriginalLandingPages = () => {
  const getPathRedirect = (path: string) => ([
    '/become-a-seller',
    '/invoicing',
    '/home-trials',
    '/omo',
    '/wholesale',
  ].includes(path)
    ? `/lp${path}`
    : null);

  const doMaybeRedirect = (path: string) => {
    const redirectPath = getPathRedirect(path);
    if (redirectPath) {
      router.push(redirectPath);
    }
  };

  const route = useRoute();
  const path = computed(() => route.path);

  doMaybeRedirect(path.value);

  watch(path, (p) => {
    doMaybeRedirect(p);
  });
};

onMounted(async () => {
  observeBrokenImages();
  enableHuspot.value = true;
  if (authStore && authStore.isAuthenticated) {
    chatStore.connectUser;

    // Ensure that roles are accurate if the user refreshes the app.
    authStore.updateRoles(userApi);

    watchCookie(CookieName.UPDATE_ROLES, async (action, value) => {
      if (action === CookieAction.CHANGED || action === CookieAction.ADDED) {
        authStore.updateRoles(userApi);
        deleteCookie(CookieName.UPDATE_ROLES);
      }
    });
  }
});

/**
 * Replaces broken images on dev/local sites with versions from production.
 */
const observeBrokenImages = () => {
  const observer = () => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        mutation.addedNodes.forEach((newNode) => {
          attachOnError(newNode);
        });
      });
    });

    observer.observe(document.querySelector('body'), {
      subtree: true,
      childList: true,
    });
  };

  observer();

  const attachOnError = (el) => {
    if (!el.tagName) {
      return;
    }

    if (el.tagName === 'IMG') {
      const getSrc = (el) => el.attributes.src.value.toString();
      const isLocalDevFrontendImage = getSrc(el)
        .startsWith('/src');
      const isRemoteFrontendImage = getSrc(el)
        .startsWith('/assets');

      const isBackendImage = getSrc(el)
        .startsWith('/')
        && !isLocalDevFrontendImage
        && !isRemoteFrontendImage;

      if (isBackendImage) {
        el.setAttribute('src', urlStore.apiBaseUrl + getSrc(el));
      }

      el.addEventListener('error', (e: Event) => {
        const errAttr = 'data-error-srcd';
        if (el.attributes[errAttr]) {
          return;
        }

        el.setAttribute(errAttr, 'true');
        // Lazy, can't remember to right way to work with URLs.
        const noProt = getSrc(el)
          .replace('https://', '')
          .replace('http', '');
        const urlArr = noProt.split('/');
        urlArr[0] = `${platformShBackendPrefix}.handcrafted.market`;
        const newSrc = urlArr.join('/');
        el.setAttribute('src', newSrc);
      });
    } else {
      const nestedImages = el.querySelectorAll('img');
      for (const img of nestedImages) {
        attachOnError(img);
      }
    }
  };
};
</script>

<template>
  <router-link
    class="wrapper"
    :to="href"
  >
    <article>
      <div
        class="masthead"
        :class="{'masthead--rounded': roundedMasthead}"
      >
        <div class="masthead__inner">
          <div
            v-if="slots.mastheadTop"
            class="masthead__top"
          >
            <slot name="mastheadTop" />
          </div>

          <img :src="imgSrc">

          <div
            v-if="slots.mastheadBottom"
            class="masthead__bottom"
          >
            <slot name="mastheadBottom" />
          </div>
        </div>
      </div>
      <HeadingHx
        class="title"
        :hx="hx"
        :class="hxSize"
      >
        {{ title }}
      </HeadingHx>

      <slot />
    </article>
  </router-link>
</template>

<script setup lang="ts">

import { useSlots } from 'vue-demi';

const props = defineProps({
  imgSrc: {
    required: true,
    type: String,
  },
  hx: {
    required: true,
    type: Number,
  },
  hxSize: {
    required: false,
    type: Number,
    default: 5,
  },
  title: {
    required: true,
    type: String,
  },
  href: {
    required: true,
    type: String,
  },
  roundedMasthead: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const slots = useSlots();

const hxSize = `h${props.hxSize}`;
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  container: card / inline-size;
}

a {
  --transition: all .2s ease-in-out;

  display: block;
  color: var(--color--copy-primary);

  &, &:hover {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    filter: revert;

    .title {
      text-decoration-color: var(--color--copy-primary);
    }

    img {
      box-shadow: var(--box-shadow);
    }
  }
}

.title {
  transition: var(--transition);
  text-decoration: underline transparent;
  margin-top: var(--space-xxs);
}

.masthead {
  position: relative;
  margin-bottom: var(--space-xs);
  transition: var(--transition);

  &:hover {
    box-shadow: var(--box-shadow);
  }

  &--rounded {
    border-radius: var(--border-radius--standard);
    overflow: hidden;
  }

  &__top {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: right;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

img {
  transition: var(--transition);
  display: block;
}
</style>

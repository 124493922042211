// =======================================================
// Common Drupal Els
// =======================================================

import { EnumType, MetatagTag } from '~/types/enum/enum';
import { UrlString } from '~/types/types';
import { createConstEnum } from '~/common/enum';

export interface DrupalDate {
  value: string,
  format: string
}

export interface DrupalCommercePrice {
  currency_code: string,
  formatted: string,
  number: string
}

export interface DrupalCommercePriceNoFormat {
  currency_code: string,
  number: string
}

export interface DrupalMeasurement {
  number: string,
  unit: string
}

export interface DrupalPath {
  alias: string | null,
  langcode: string,
  pid: string | null
}

export interface DrupalUri {
  url: string,
  value: string
}

export interface DrupalLinkField {
  options: [],
  title: string | null,
  uri: string,
}

export interface DrupalLinkFieldTitleRequired extends Omit<DrupalLinkField, 'title'> {
  title: string,
}

export interface AddressFormDrupalAddress {
  given_name: string | null,
  address_line1: string | null,
  address_line2: string | null,
  locality: string | null,
  administrative_area: string | null,
  postal_code: string | null,
}

export interface DrupalAddress extends AddressFormDrupalAddress {
  additional_name: string | null,
  administrative_area: string | null,
  country_code: string | null,
  dependent_locality: string | null,
  family_name: string | null,
  langcode: string | null,
  organization: string | null,
  sorting_code: string | null,
}

export interface Metatag {
  attributes: {
    // For MetatagTag.LINK
    href?: UrlString,
    rel?: string,
    // For MetatagTag.META
    content?: string,
    name?: string,
    property?: string
  },
  tag: MetatagTag
}

export interface Attributes {
  [index: string]: any,

  metatag?: Metatag[]
}

export const DrupalRole = createConstEnum({
  AUTHENTICATED: 'authenticated',
  TAX_EXEMPT: 'tax_exempt',
  SELLER: 'merchant',
  ADMINISTRATOR: 'administrator',
  VERIFIED: 'verified',
});

export type DrupalRole = EnumType<typeof DrupalRole>;
